var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "result-component"
    }
  }, [!_vm.isEmpty(_vm.getSearchFlightArray) ? _c('div', [_c('b-tabs', {
    attrs: {
      "pills": "",
      "nav-class": "tab-title",
      "align": "center",
      "nav-wrapper-class": "nav-wrapper-class"
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.getSearchFlightArray, function (itinerary, itineraryIndex) {
    return _c('b-tab', {
      key: itineraryIndex,
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex-column"
          }, [_c('div', {
            class: "d-flex text_info_class_booking fw-700 mb-50 text-nowrap\n              ".concat(['xs', 'sm', 'md'].includes(_vm.appBreakPoint) ? 'font-medium-1' : 'font-medium-3')
          }, [_vm._v(" #" + _vm._s(itineraryIndex + 1) + " " + _vm._s(itinerary.startPoint) + "-" + _vm._s(itinerary.endPoint) + " " + _vm._s(_vm.convertISODateTime(itinerary.departDate).dayAndMonth) + " ")]), !_vm.selectedTrips[itineraryIndex] || !_vm.selectedTrips[itineraryIndex][0] ? _c('div', {
            staticClass: "d-flex-center"
          }, [_c('span', {
            staticClass: "text-warning font-weight-bolder font-small-4"
          }, [_vm._v(" " + _vm._s(_vm.$t('flight.notSelectedFlights')) + " ")])]) : _vm.selectedTrips[itineraryIndex] && _vm.selectedTrips[itineraryIndex][0] ? _c('div', {
            staticClass: "d-flex-center"
          }, [_c('div', {
            staticClass: "text-nowrap font-weight-bolder"
          }, [_vm._v(" " + _vm._s(_vm.selectedTrips[itineraryIndex][0].airline) + _vm._s(_vm.selectedTrips[itineraryIndex][0].flightNumber) + " " + _vm._s(_vm.selectedTrips[itineraryIndex][0].bookingClass.code) + " " + _vm._s(_vm.convertISODateTime(_vm.selectedTrips[itineraryIndex][0].departureDate, _vm.selectedTrips[itineraryIndex][0].departureTimezone).hourMin) + " " + _vm._s(_vm.convertISODateTime(_vm.selectedTrips[itineraryIndex][0].arrivalDate, _vm.selectedTrips[itineraryIndex][0].arrivalTimezone).hourMin) + " ")])]) : _vm._e()])];
        },
        proxy: true
      }], null, true)
    }, [!_vm.refLoading && _vm.dataTrips[itineraryIndex] && _vm.dataTrips[itineraryIndex].trips && !_vm.isEmpty(_vm.dataTrips[itineraryIndex].trips) ? [_c('div', {
      staticClass: "scroll-customize"
    }, _vm._l(_vm.dataTrips[itineraryIndex].trips, function (trip, tripIndex) {
      return _c('b-card', {
        key: tripIndex,
        staticClass: "border-info shadow mb-50",
        attrs: {
          "body-class": "px-50 py-25"
        }
      }, _vm._l(trip.segments, function (segment, segmentIndex) {
        return _c('b-row', {
          key: segmentIndex,
          attrs: {
            "no-gutters": ""
          }
        }, [_c('b-col', {
          staticClass: "text-dark d-flex flex-wrap",
          class: segmentIndex > 0 ? 'pl-2' : '',
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center fw-700 font-medium-1 mr-75"
        }, [segmentIndex === 0 ? _c('b-badge', {
          staticClass: "mr-25 fw-700",
          attrs: {
            "variant": "info",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(tripIndex + 1) + " ")]) : _vm._e(), _c('span', {
          staticClass: "mr-50 text-nowrap"
        }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).time) + "-" + _vm._s(_vm.convertISODateTime(segment.arrivalDate, segment.arrivalTimezone).time))]), _c('span', {
          staticClass: "mr-50 text-nowrap"
        }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departureDate, segment.departureTimezone).dayAndMonth))]), _c('span', {
          staticClass: "mr-50 text-nowrap text-danger"
        }, [_vm._v(_vm._s("".concat(segment.airline).concat(segment.flightNumber)))]), _c('span', {
          staticClass: "mr-50 text-nowrap"
        }, [_vm._v(_vm._s("".concat(segment.departure).concat(segment.arrival)))]), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s("| ".concat(segment.airCraft)))])], 1), !['VN1A'].includes(_vm.airlineSearch) ? _c('b-button', {
          staticClass: "float-right rounded-lg px-50 py-25 my-25 mr-25 ml-0",
          staticStyle: {
            "min-width": "80px"
          },
          attrs: {
            "variant": "flat-warning",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModalInfoTrip(segment);
            }
          }
        }, [_c('span', {
          staticClass: "border-bottom-warning"
        }, [_vm._v("Tình trạng chuyến bay")])]) : _vm._e()], 1), _c('b-col', {
          class: segmentIndex > 0 ? 'pl-2' : '',
          attrs: {
            "cols": "12"
          }
        }, [['1G'].includes(_vm.airlineSearch) ? [_vm._l(_vm.groupBy(segment.bookingClassAvail, 'groupClass'), function (values, key, groupClassIndex) {
          return _c('div', {
            key: groupClassIndex,
            staticClass: "mb-25 ml-50"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            class: "".concat(key === 'Business' ? 'text-success' : '', " mr-50 fw-700")
          }, [_vm._v(" " + _vm._s(key.slice(0, 3)) + " ")]), _c('div', _vm._l(values, function (bookingClass, bookingClassIndex) {
            return _c('b-button', {
              key: bookingClassIndex,
              class: "\n                            btn_booking_class mr-25 mb-25 px-25 py-0\n                            ".concat(_vm.resolveClassColor(bookingClass), "\n                            "),
              attrs: {
                "id": "btn-booking-class-".concat(bookingClass.uniqueBookingClassId),
                "variant": "transparent"
              },
              on: {
                "click": function click($event) {
                  return _vm.handleSelectClassBooking(segment, bookingClass);
                }
              }
            }, [_c('div', [_c('span', {
              class: "fw-700"
            }, [_vm._v(_vm._s(bookingClass.code))]), _vm._v(" "), _c('span', [_vm._v("(" + _vm._s(bookingClass.availability) + ")")])]), _vm.appBreakPoint !== 'xs' ? _c('b-tooltip', {
              attrs: {
                "id": "tooltip-booking-class-".concat(bookingClass.uniqueBookingClassId),
                "target": "btn-booking-class-".concat(bookingClass.uniqueBookingClassId),
                "triggers": "hover",
                "custom-class": "tooltip-index-2",
                "placement": "top",
                "boundary": "window",
                "variant": _vm.resolveTooltipVariant(bookingClass)
              }
            }, [_c('div', {
              staticClass: "d-flex-center text-nowrap"
            }, [bookingClass.prices[0].fareBasisCode ? _c('div', {
              staticClass: "text-white mr-1 font-small-4"
            }, [_vm._v(" " + _vm._s(bookingClass.prices[0].fareBasisCode) + " ")]) : _vm._e()]), _c('div', {
              class: "font-italic text-white font-small-4"
            }, [_vm._v(" (" + _vm._s(_vm.$t('flight.fareNotIncludeServiceFee')) + ") ")])]) : _vm._e()], 1);
          }), 1)])]);
        }), _c('i', {
          staticClass: "text-danger font-weight-bolder text-uppercase text-nowrap font-small-3"
        }, [_vm._v(_vm._s(segment.operating ? _vm.getAirlineNameByCode(segment.operating) : ''))])] : ['VU'].includes(_vm.airlineSearch) ? [_vm._l(segment.bookingClassAvail, function (bookingClass, index) {
          return _c('b-button', {
            key: index,
            class: "\n                        btn_booking_class mr-25 mb-25 px-25 py-0\n                        ".concat(_vm.resolveClassColor(bookingClass), "\n                        "),
            attrs: {
              "id": "btn-booking-class-".concat(bookingClass.uniqueBookingClassId),
              "variant": "transparent"
            },
            on: {
              "click": function click($event) {
                return _vm.handleSelectClassBooking(segment, bookingClass);
              }
            }
          }, [_c('div', [_c('span', {
            class: "fw-700 ".concat(bookingClass.refundable ? '' : 'text-danger')
          }, [_vm._v(_vm._s(bookingClass.code))]), _c('span', [_vm._v("(" + _vm._s(bookingClass.availability) + ")")]), _vm.indexBookingClassShowPrice === bookingClass.uniqueBookingClassId || bookingClass.firstPriceShow || _vm.listSelectedClassId.includes(bookingClass.uniqueBookingClassId) ? _c('span', {
            staticClass: "fw-700"
          }, [_vm._v(" " + _vm._s("= ".concat(_vm.resolvePriceShow(bookingClass))) + " ")]) : _vm._e()]), _vm.appBreakPoint !== 'xs' ? _c('b-tooltip', {
            attrs: {
              "id": "tooltip-booking-class-".concat(bookingClass.uniqueBookingClassId),
              "target": "btn-booking-class-".concat(bookingClass.uniqueBookingClassId),
              "triggers": "hover",
              "custom-class": "tooltip-index-2",
              "placement": "top",
              "boundary": "window",
              "variant": _vm.resolveTooltipVariant(bookingClass)
            }
          }, [_c('div', {
            staticClass: "d-flex-center text-nowrap"
          }, [bookingClass.prices[0].fareBasisCode ? _c('div', {
            staticClass: "text-white mr-1 font-small-4"
          }, [_vm._v(" " + _vm._s(bookingClass.prices[0].fareBasisCode) + " ")]) : _vm._e(), ['VU'].includes(bookingClass.source) ? _c('div', {
            class: "fw-800 font-small-4 ".concat(bookingClass.refundable ? 'text-white' : 'text-danger')
          }, [_vm._v(" " + _vm._s(bookingClass.refundable ? 'Được phép hoàn' : 'Không được hoàn') + " ")]) : _vm._e()]), _c('div', {
            class: "font-italic text-white font-small-4"
          }, [_vm._v(" (" + _vm._s(_vm.$t('flight.fareNotIncludeServiceFee')) + ") ")])]) : _vm._e()], 1);
        }), _c('i', {
          staticClass: "text-danger font-weight-bolder text-uppercase text-nowrap font-small-3"
        }, [_vm._v(_vm._s(segment.operating ? _vm.getAirlineNameByCode(segment.operating) : ''))])] : ['VN1A'].includes(_vm.airlineSearch) ? [_vm._l(segment.bookingClassAvail, function (bookingClass, index) {
          return _c('b-button', {
            key: index,
            class: "\n                        btn_booking_class mr-25 mb-25 px-25 py-0\n                        ".concat(_vm.resolveClassColor(bookingClass), "\n                        "),
            attrs: {
              "id": "btn-booking-class-".concat(bookingClass.uniqueBookingClassId),
              "variant": "transparent"
            },
            on: {
              "click": function click($event) {
                return _vm.handleSelectClassBooking(segment, bookingClass);
              }
            }
          }, [_c('div', [_c('span', {
            staticClass: "fw-700"
          }, [_vm._v(_vm._s(bookingClass.code))]), _c('span', [_vm._v("(" + _vm._s(bookingClass.availability) + ")")])]), _vm.appBreakPoint !== 'xs' && ['DS', 'LL'].includes(bookingClass === null || bookingClass === void 0 ? void 0 : bookingClass.status) ? _c('b-tooltip', {
            attrs: {
              "id": "tooltip-booking-class-".concat(bookingClass.uniqueBookingClassId),
              "target": "btn-booking-class-".concat(bookingClass.uniqueBookingClassId),
              "triggers": "hover",
              "custom-class": "tooltip-index-2",
              "placement": "top",
              "boundary": "window",
              "variant": _vm.resolveTooltipVariant(bookingClass)
            }
          }, [_c('div', {
            staticClass: "d-flex-center text-nowrap"
          }, [_vm._v(" Đặt sổ chờ: " + _vm._s(bookingClass.status) + " ")])]) : _vm._e()], 1);
        }), _vm.isEmpty(segment.bookingClassAvail) ? _c('b-badge', {
          staticClass: "fw-700 pt-50 pb-25",
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" Hành trình hết chỗ ")]) : _vm._e(), _c('i', {
          staticClass: "text-danger font-weight-bolder text-uppercase text-nowrap font-small-3"
        }, [_vm._v(_vm._s(segment.operating ? _vm.getAirlineNameByCode(segment.operating) : ''))])] : [_c('div', {
          staticClass: "fw-700 text-danger"
        }, [_vm._v(" Hệ thống đặt vé: " + _vm._s(_vm.airlineSearch) + " ")])]], 2), trip.segments.length > 1 && segmentIndex === 0 ? _c('hr', {
          staticClass: "mx-3 mx-md-5 w-100 mb-0 mt-25"
        }) : _vm._e()], 1);
      }), 1);
    }), 1), _vm.isLoadingSearchMore ? _c('div', {
      staticClass: "mt-75 fw-700 text-warning d-flex-center flex-column flex-lg-row"
    }, [_c('b-spinner', {
      staticClass: "mr-lg-1",
      attrs: {
        "small": "",
        "variant": "warning"
      }
    }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("flight['Loading more flights']")) + " ")])], 1) : _vm._e(), _c('div', {
      staticClass: "sticky_refresh d-flex-center"
    }, [['1G', 'VN1A'].includes(_vm.airlineSearch) ? _c('div', {
      staticClass: "sticky_refresh show mr-50 mr-md-1"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "d-flex-center py-50 px-75",
      attrs: {
        "variant": "info",
        "pill": ""
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenCalculatePriceModal('result');
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "DollarSignIcon",
        "size": "18"
      }
    }), _vm._v(" " + _vm._s(_vm.$t('reservation.recalculatePrice.title')) + " ")], 1)], 1) : _vm._e(), ['1G'].includes(_vm.airlineSearch) ? _c('div', {
      staticClass: "sticky_refresh show mr-50 mr-md-1"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "d-flex-center py-50 px-1",
      attrs: {
        "variant": "info",
        "pill": "",
        "disabled": !_vm.getNextResultReference
      },
      on: {
        "click": _vm.handleSearchMoreResult
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "PlusIcon",
        "size": "18"
      }
    }), _vm._v(" " + _vm._s(_vm.$t('flight.loadMore')) + " ")], 1)], 1) : _vm._e(), _c('b-button', {
      staticClass: "px-1 py-50 mr-50 mr-md-1 rounded-pill d-flex-center",
      attrs: {
        "variant": "warning"
      },
      on: {
        "click": _vm.handleRefreshResultSearch
      }
    }, [_c('feather-icon', {
      class: "".concat(_vm.isMobileView ? '' : 'mr-25'),
      attrs: {
        "icon": "RefreshCwIcon",
        "size": "18"
      }
    }), _vm._v(" " + _vm._s(_vm.isMobileView ? '' : 'Làm mới') + " ")], 1), _c('div', {
      staticClass: "sticky_refresh show"
    }, [_c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      class: "d-flex-center py-50 px-1 ".concat(['1G'].includes(_vm.airlineSearch) ? 'mr-50 mr-md-1' : ''),
      attrs: {
        "variant": "info",
        "pill": ""
      },
      on: {
        "click": function click() {
          return _vm.$emit('scrollToTarget');
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "ArrowDownIcon",
        "size": "18"
      }
    })], 1)], 1)], 1)] : !_vm.refLoading && _vm.dataTrips[itineraryIndex] && _vm.dataTrips[itineraryIndex].trips && _vm.isEmpty(_vm.dataTrips[itineraryIndex].trips) ? _c('b-alert', {
      staticClass: "text-center px-50 px-lg-0 py-75 py-lg-1 my-2 font-weight-bolder font-medium-2",
      attrs: {
        "variant": "danger",
        "show": ""
      }
    }, [_c('feather-icon', {
      staticClass: "text-danger mr-25",
      staticStyle: {
        "margin-bottom": "3px"
      },
      attrs: {
        "icon": "InfoIcon",
        "size": "21"
      }
    }), _vm._v(" Không có chuyến bay được khai thác trên hành trình này ")], 1) : _vm._e(), _vm.refLoading ? _c('div', {
      staticClass: "d-flex-center flex-column",
      staticStyle: {
        "height": "80px"
      }
    }, [_c('b-spinner', {
      staticClass: "mt-1 mb-50",
      attrs: {
        "variant": "info"
      }
    }), _c('div', {
      staticClass: "text-airline font-weight-bolder"
    }, [_vm._v(" Hệ thống đang tìm kiếm chuyến bay, vui lòng đợi... ")])], 1) : _vm._e()], 2);
  }), 1)], 1) : _c('b-card', {
    staticClass: "text-center d-flex-center",
    staticStyle: {
      "min-height": "200px"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('span', [_vm._v(" Không tìm thấy thông tin chuyến bay, vui lòng tải lại trang và thực hiện lại ")])]), _c('ModalInfoTrip', {
    attrs: {
      "segment-info": _vm.segmentInfo
    }
  }), _c('ModalCalcPriceClassBooking', {
    attrs: {
      "modal-id": 'result'
    },
    on: {
      "scrollToCardShowPrice": _vm.scrollToCardShowPrice
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }